import axios from "axios";
import { ItemType } from "../Models/ItemModel";
import { appConfig } from "../utils/appConfig";


export const getAllProducts = async (): Promise<ItemType[]> => {
    const fullUrl = appConfig.baseUrl + appConfig.get.allProducts;

    const data = await axios
    .get(fullUrl)
    .then((res) => res.data as ItemType[])
    .catch(e => console.log(e))

    return data ? data as ItemType[]: []; 
}

export const getnewProducts = async (): Promise<ItemType[]> => {
    const fullUrl = appConfig.baseUrl + appConfig.get.newProducts;

    const data = await axios
    .get(fullUrl)
    .then((res) => res.data as ItemType[])
    .catch(e => console.log(e))

    return data ? data as ItemType[]: []; 
}

export const getpopularProducts = async (): Promise<ItemType[]> => {
    const fullUrl = appConfig.baseUrl + appConfig.get.popularproducts;

    const data = await axios
    .get(fullUrl)
    .then((res) => res.data as ItemType[])
    .catch(e => console.log(e))

    return data ? data as ItemType[]: []; 
}


export const getSpecialProducts = async (): Promise<ItemType[]> => {
  const fullUrl = appConfig.baseUrl + appConfig.get.specialProducts;

  const data = await axios
  .get(fullUrl)
  .then((res) => res.data as ItemType[])
  .catch(e => console.log(e))

  return data ? data as ItemType[]: []; 
}

export const addProduct = async (product: ItemType, image:string | File) => {
    const uploadImageFullUrl = appConfig.baseUrl + appConfig.post.uploadImage;
    const addProductFullUrl = appConfig.baseUrl + appConfig.post.addProduct;

    const formData = new FormData();
    formData.append('product', image);

    const data = await axios
    .post(uploadImageFullUrl,formData)
    .then((res) => res.data)
    .catch(err => {
        console.log(err);
        console.error('Error uploading file:', err.response ? err.response.data : err.message);
        throw err;
    })

    if (data.success){
        product.image = data.image_url;
        console.log(product);

         const productData = await axios
        .post(addProductFullUrl,product) //Json.stringify(product)
        .then((res) => res.data)
        .then((productData) => {productData.message === "Created Successfully" ?alert("Product Added"):alert("Failed")})
        .catch(err => {
            console.log(err);
            console.error('Error Adding Product:', err.response ? err.response.data : err.message);
            throw err;
        })
        return productData;
    }
}

export const updateProduct = async (product: ItemType, image:string | File): Promise<ItemType| void> => {
  const uploadImageFullUrl = appConfig.baseUrl + appConfig.post.uploadImage;
  const updateProductFullUrl = appConfig.baseUrl + appConfig.update.updateProduct;
  
  console.log(image);
  console.log(product.image);
  
  if (product.image.replace('/src', '') !== image){
    const formData = new FormData();
    formData.append('product', image);

    const data = await axios
    .post(uploadImageFullUrl,formData)
    .then((res) => res.data)
    .catch(err => {
        console.log(err);
        console.error('Error uploading file:', err.response ? err.response.data : err.message);
        throw err;
    })

    if (data.success){
      product.image = data.image_url;
      console.log(product);
    }

  }
  const productData = await axios
  .put(updateProductFullUrl,product) //Json.stringify(product)
  .then((res) => res.data)
  .then((productData) => {productData.message === "Product updated successfully." ?alert("Product Updated"):alert("Failed")})
  .catch(err => {
      console.log(err);
      console.error('Error Updating Product:', err.response ? err.response.data : err.message);
      throw err;
  })
  return productData;

}



export const removeProduct = async (id: number): Promise<void> => {
    const fullUrl = appConfig.baseUrl + appConfig.post.removeProduct;

    await axios
    .post(fullUrl, ({"id":id}))
    .then((res) => console.log("Product was removed status: " + res.status))
    .catch(e => console.log(e))

}

export const searchProducts = async (query: string) => {
    const fullUrl = appConfig.baseUrl + appConfig.get.productSearch;

    try {
      const response = await axios
      .get(fullUrl,{
        params: {
          query: query,
        },
      });
      console.log("services" + response.data);
      return response.data;

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

export const suggestProducts = async(productName: string, suggestedBy: string) => {
    const fullUrl = appConfig.baseUrl + appConfig.post.suggestProduct;

    try {
        const response = await axios.post(fullUrl, {
          name: productName,
          suggestedBy: suggestedBy, 
        });
        
        return response.data.message;
        
      } catch (error) {
        console.error('Failed to submit product suggestion', error);
      }
};
