import React, { useEffect, useState } from "react";
import "./ListProduct.css";
import cross_icon from '../Assets/cross_icon.png'
import { getAllProducts, removeProduct, updateProduct } from "../../Services/productServices";
import { ItemType } from "../../Models/ItemModel";
import Button from "@mui/material/Button";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { EditProduct } from "./EditProduct";

export const ListProduct = () => {
  const [allproducts, setAllProducts] = useState<ItemType[]>([]);
  const [editProduct, setEditProduct] = useState<ItemType | null>(null);

  const fetchAllProducts = async () =>{
    const allProducts = await getAllProducts();
    setAllProducts(allProducts);
    console.log(allProducts);
  }

  useEffect(() => {
    fetchAllProducts();
  }, [])


  const handleRemoveProduct = async(id: number) =>{
    await removeProduct(id);
    await fetchAllProducts();
  }

  const handleEditClick = (product: ItemType) => {
    setEditProduct(product);  
  };

  const handleEditCancel = () => {
    setEditProduct(null);
  };

  const handleEditSave = async (updatedProduct: ItemType, image: string| File) => {
    try {
      const newUpdatedProduct = await updateProduct(updatedProduct, image);
      // setAllProducts((prevProducts) => 
      //   prevProducts.map((p) => (p.id === updatedProduct.id ? newUpdatedProduct : p))
      // );
      
      setAllProducts((prevProducts) => {
        if (!newUpdatedProduct) return prevProducts; // If the update fails, return the original array
        console.log(newUpdatedProduct.name);
        return prevProducts.map((p) =>
          p.id === updatedProduct.id ? newUpdatedProduct : p
        );
      });

      handleEditCancel();
      await fetchAllProducts();
    } catch (err:any) {
      console.log("Failed to update vacation", err);
      alert(err.response ?'An error occurred while updating the product : ' + err.response.data : 'An error occurred while updating the product.');
      // setSnackbarMessage(err.response ?'An error occurred while updating the vacation : ' + err.response.data : 'An error occurred while updating the vacation.');
      // setSnackbarSeverity("error");
      // setSnackbarOpen(true);
    }
  };  
    
  return (
    <div className="listproduct">
      <h1>רשימת מוצרים</h1>
      <div className="listproduct-format-main">
          <p>תמונה</p>
          <p>תיאור המוצר</p>
          <p>מחיר</p>
          <p>מחיר אחרי הנחה</p>
          <p>קטגוריה</p>
          {/* <p>הסר מוצר</p> */}
      </div>
      <div className="listproduct-allproducts">
        {allproducts.map((product, index) => {
          return (
            <div>
              <div key={product.id} className="listproduct-format-main listproduct-format">
                <img className="listproduct-product-icon"  src={product.image.replace('/src', '')}/>
                <p className="product-name">{product.name}</p>
                <p>{'\u20AA'}{product.old_price}</p>
                <p>{'\u20AA'}{product.new_price}</p>
                <p>{product.category}</p>
                {/* <img className="listproduct-remove-icon" onClick={()=>{handleRemoveProduct(product.id)}} src={cross_icon} alt="" /> */}
                <div>
                  <Button onClick={()=>{handleEditClick(product)}} className="adminUserBtn"><EditIcon /></Button>
                  <Button onClick={()=>{handleRemoveProduct(product.id)}} className="adminUserBtn"><DeleteForeverIcon /></Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {editProduct && (
          <EditProduct
            product={editProduct}
            open={!!editProduct}
            onClose={handleEditCancel}
            onSave={handleEditSave}
            action={"edit"}
          />
        )}
    </div>
  ); 
};

