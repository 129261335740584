import './NavbarAdmin.css'
import navlogo from '../Assets/logo.png'
// import navprofileIcon from '../Assets/nav-profile.svg'

export const NavbarAdmin = () => {
  return (
    <div className='navbar-admin'>
      {/* <div>
        <img src={navprofileIcon} className='nav-profile' alt="" />
      </div> */}
      <div>
        <h3> Hanayadem - Admin Panel</h3>
      </div>
    </div>
  )
}

