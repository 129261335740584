import React, { useState } from 'react'
import './ForgotPassword.css'
import { sendPasswordResetLink } from '../../Services/authServices';
import { useNavigate } from 'react-router-dom';

export const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Call the API to send the reset link
        const response = await sendPasswordResetLink(email);
        console.log(response);
        if(response === "OK"){
            navigate("/התחברות")
        }
    }
    return (
        <div className='forgotPassword-container'>
            <h1>  ?שכחת את הסיסמה שלך </h1>
            <form className = "forgotPassword-form" onSubmit={handleSubmit}>
                <p> נא לרשום את הדוא"ל שנרשמת בו לאתר</p>
                {/* <label>דוא"ל :</label> */}
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">שלח לינק לאיפוס סיסמה</button>
            </form>
        </div>
    );
}

