import React, { useEffect, useState } from 'react'
import './specials.css'
import new_collections from '../Assets/new_collections'
import { Item } from '../Item/Item'
import { getSpecialProducts } from '../../Services/productServices'
import { ItemType } from '../../Models/ItemModel'

export const Specials = () => {

  const [specialProducts, setSpecialProducts] = useState<ItemType[]>([]);

  useEffect(() => {
    const fetchSpecialProducts = async() => {
      const products = await getSpecialProducts();
      setSpecialProducts(products);
    }
    fetchSpecialProducts();
  }, []);

  return (
    <div className='specials'>
        <h1>המיוחדים שלנו</h1>
        <hr />
        <div className="all-specials">
        {specialProducts.map((item, i) => {
                return <Item key={i} 
                id={item.id} 
                name={item.name}
                category=""
                image={item.image} 
                new_price={item.new_price} 
                old_price={item.old_price}/>
            })}
        </div>
    </div>
  )
}
