import React from 'react'
import "./hero.css"
import hand_icon from "../Assets/hand_icon.png"
import arrow_icon from "../Assets/arrow.png"
import hero_image from "../Assets/8.png"
import { useNavigate } from 'react-router-dom'

export const Hero = () => {
    const navigate = useNavigate();

    const handleNewProducts = () =>{
      navigate("newProducts"); 
    }
    return (
        <div className='hero'>
            <div className="hero-left">
                <img src={hero_image} alt="" />
            </div>
            <div className="hero-right">
                <h2> מוצרים חדשים בלבד</h2>
                <div>
                    <div className="hero-hand-icon">
                        <img src={hand_icon} alt="" />
                        <p>בשבילכם</p>
                    </div>
                    <p>מוצרים חדשים</p>
                    <p>בכל הקטיגוריות</p>
                </div>
                <div className="hero-latest-btn" onClick={handleNewProducts}>
                    <div> גלה כאן</div>
                    <img src={arrow_icon} alt="" />
                </div>
            </div>
        </div>
    )
}
