import React from "react";
import { Typography } from "@mui/material";
import "./AboutUs.css";
import logo from '../Assets/logo_new18.png'

export const AboutUs = () => {
  return (
    <div className="aboutUsMainContainer">
      <div className="aboutDiv">
        <div className="heroDiv">
          <div className="heroLeft heroScrollRight">HaNA</div>
          <div className="heroRight heroScrollLeft">YADEEM</div>
        </div>
        <div className="sidesDiv">
          <div className="sideCard leftSide">
          <img src={logo} alt="" />
            {/* <Typography sx={{ color: 'black' }} variant="h6">Fadi Zedan</Typography>
            <Typography variant="body2">Founder & Developer</Typography>
            <Typography variant="overline" sx={{display:"flex" , justifyContent:"right"}}>Full-Stack</Typography> */}
          </div>
          <div className="middle scrollDown">
            <Typography sx={{ color: '#5ebef7', direction:'rtl' }} variant="h4">אודות / מי אנחנו?</Typography>
            <hr />
            <Typography variant="body1">
              <Typography sx={{ color: '#5ebef7' }} variant="h6">הסיפור שלנו</Typography>
              ברוכים הבאים לאתר שלנו! נולדנו מתוך ראייה מעמיקה של הקשיים שבעלי בר נייד נתקלים בהם כשעליהם לקנות מוצרים מהרבה מקומות, להוביל אותם ולסחוב אותם למקום האירוע. הבנו שצריך לספק פתרון כולל ואיכותי במקום אחד, ובכך להקל על בעלי ברים ניידים ולחסוך להם זמן ומאמץ. מתחילת הדרך, חזוננו היה להפוך כל אירוע לחוויה ייחודית ובלתי נשכחת, בזכות המוצרים המובחרים והשירות האישי שלנו
              <hr/>
              <Typography sx={{ color: '#5ebef7' }} variant="h6">השרות שלנו</Typography>
              מה שמייחד אותנו הוא השירות האישי והמקצועי שאנו מספקים בכל רגע. אנחנו יודעים שכל לקוח הוא ייחודי ומתחייבים לתת מענה מושלם לכל צורך ובקשה. אבל זה לא הכל - אנחנו מגיעים לכל מקום בארץ! לא משנה איפה אתם נמצאים, אנו נדאג שהמוצרים שלכם יגיעו אליכם במהירות וביעילות, תמיד בזמן ובמקום שנוח לכם
              <hr/>
              <Typography sx={{ color: '#5ebef7' }} variant="h6">המטרה שלנו</Typography>
              המטרה שלנו היא לספק לכם את כל מה שבר נייד צריך ולהפוך את האירוע שלכם לבלתי נשכח. אנחנו כאן כדי להעניק לכם את חוויית השירות הטובה ביותר בשוק, עם מוצרים איכותיים ושירות אישי ומקצועי.<br></br> ! הצטרפו אלינו ותיהנו 
            </Typography>
            <hr />
          </div>
          <div className="sideCard rightSide">
            <Typography sx={{ color: '#5ebef7', fontSize: 40 }} variant="h6">הניידים</Typography>
            <Typography sx={{ color: 'white', fontSize: 28 }} variant="body2">באים אליך לכל מקום</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
