import React, { useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import { resetPassword } from '../../Services/authServices';
import './ResetPassword.css'

export const ResetPassword = () => {

    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const { token } = useParams();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)  => {
        event.preventDefault();

        if (token === undefined) {
            console.error('No token found in URL parameters.');
            alert('No token found in URL parameters.');
            return;
        }

        try{
            // Call the API to reset the password
            const response = await resetPassword(password, token);
            console.log(response);
            if(response === "OK"){
                console.log('Password reset successful:', response);
                alert('סיסמתך הוחלפה בהצלחה');
                navigate("/התחברות")
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            alert('איפוס הסיסמה שלך לא הצליח - אנה נסה שוב');
          }
     };

    return (
        <div className='resetPassword-container'>
            <h1>איפוס סיסמה</h1>
            <form className = "resetPassword-form" onSubmit={handleSubmit}>
                <label>
                  הכנס סיסמה חדשה:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
                <button type="submit">אפס סיסמה</button>
            </form>
        </div>
    );
};

export default ResetPassword;

