import { Outlet, createBrowserRouter } from "react-router-dom";

import {Navbar} from "../components/Navbar/Navbar";
import {Footer} from "../components/Footer/Footer";
import { Shop } from "../Pages/Shop";
import { ShopCategory } from "../Pages/ShopCategory";
import { Cart } from "../Pages/Cart";
import { Product } from "../Pages/Product";
import { LoginSignup } from "../Pages/LoginSignup";
import ice_banner from '../components/Assets/banners/ice_banner3.png'
import alcohol_banner from '../components/Assets/banners/alcohol_banner.png'
import soft_drink_banner from '../components/Assets/banners/soft_drink_banner_2.png'
import food_snacks_banner from '../components/Assets/banners/food_snacks.png'
import disposable_products_banner from '../components/Assets/banners/disposable_banner.png'
import clean_products_banner from '../components/Assets/banners/clean_banner.png'
import related_products_banner from '../components/Assets/banners/related_banner1.png'
import equipment_rental_banner from '../components/Assets/banners/rental_banner.png'
import sale_banner from '../components/Assets/banners/sale_banner.gif'
import { ListProduct } from "../components/ListProduct/ListProduct";
import {Admin} from "../Pages/Admin";
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { ProductsSearch } from "../Pages/ProductsSearch";
import { NewProducts } from "../Pages/NewProducts";
import all_categories from "../components/Assets/all_categories"
import { all } from "axios";
import { getSubCategories } from "../utils/utils";
import { AboutUs } from "../components/AboutUs/AboutUs";
import { ForgotPassword } from "../components/ForgotPassword/ForgotPassword";
import { ResetPassword } from "../components/ResetPassword/ResetPassword";


const mainCategory = "אלכוהול";
const subCategories = getSubCategories(mainCategory);
console.log(subCategories);

const alcoholSubCategoryRoutes = subCategories.map((subCategory) => ({
    path: `/${subCategory}`,
    element: (
        <ShopCategory
            banner={alcohol_banner}
            category={subCategory}
            subCategories={getSubCategories(subCategory)}
        />
    ),
}));

const foodCategory = "אוכל ונשנושים"
const foodSubCategories = getSubCategories(foodCategory);
console.log(foodSubCategories);

const foodSubCategoryRoutes = foodSubCategories.map((subCategory) => ({
    path: `/${subCategory}`,
    element: (
        <ShopCategory
            banner={food_snacks_banner}
            category={subCategory}
            subCategories={getSubCategories(subCategory)}
        />
    ),
}));

const categoriesRoutes = all_categories.map((category) => ({
    path: `/${category.category}`,
    element: (
        <ShopCategory
            banner={category.banner}
            category={category.category}
            subCategories={getSubCategories(category.category)}
        />
    ),
}));



export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <>
                <Navbar/>
                <Outlet />
                <Footer />
            </>
        ),
        children: [
            { path: "/", element:<Shop/>},
            { path: "/payment", element: <AboutUs/>},
            { path: "/אודות", element: <AboutUs/>},
            { path: "/Product/:id", element: <Product/>},
            { path: "/cart", element: <Cart/>},
            { path: "/התחברות", element: <LoginSignup/>},
            { path: "/forgotPassword", element: <ForgotPassword/>},
            { path: "/reset-password/:token", element: <ResetPassword/>},
            { path: "/user", element:<Shop/>},
            { path:"/admin",
                element: (
                <ProtectedRoute role="admin">
                    <Admin/>
                </ProtectedRoute>
                ),
            },
            // { path: "/addproduct", 
            //     element: (
            //         <ProtectedRoute role="admin">
            //             <AddOrEditProduct/>
            //         </ProtectedRoute>
            //         ),
            // },
            { path: "/listproduct", 
                element: (
                    <ProtectedRoute role="admin">
                        <ListProduct/>
                    </ProtectedRoute>
                    ),
            },
            { path: "/searchResults", element: <ProductsSearch/>},
            { path: "/newProducts", element: <NewProducts/>},
            ...categoriesRoutes,
            ...alcoholSubCategoryRoutes,
            ...foodSubCategoryRoutes,  
            // { path: "/וודקה", element: <ShopCategory banner={alcohol_banner} category="וודקה" subCategories={getSubCategories("וודקה")}/>},
            // { path: "/בירה", element: <ShopCategory banner={alcohol_banner} category="בירה" subCategories={getSubCategories("בירה")}/>},
            // { path: "/יין", element: <ShopCategory banner={alcohol_banner} category="יין" subCategories={getSubCategories("יין")}/>},
            // { path: "/טקילה", element: <ShopCategory banner={alcohol_banner} category="טקילה" subCategories={getSubCategories("טקילה")}/>},
            // { path: "/ג'ין", element: <ShopCategory banner={alcohol_banner} category="ג'ין" subCategories={getSubCategories("ג'ין")}/>},
            // { path: "/ערק", element: <ShopCategory banner={alcohol_banner} category="gre" subCategories={getSubCategories("ערק")}/>},
            // { path: "/ויסקי", element: <ShopCategory banner={alcohol_banner} category="ויסקי" subCategories={getSubCategories("ויסקי")}/>},  
             // { path: "/קרח", element: <ShopCategory banner={ice_banner} category="קרח"/>},
            // { path: "/אלכוהול", element: <ShopCategory banner={alcohol_banner} category="אלכוהול" subCategories={getSubCategories("אלכוהול")}/>},
            // { path: "/שתייה קלה", element: <ShopCategory banner={soft_drink_banner} category="שתייה קלה" subCategories={getSubCategories("שתייה קלה")}/>},
            // { path: "/אוכל ונשנושים", element: <ShopCategory banner={food_snacks_banner} category="אוכל ונשנושים" subCategories={getSubCategories("אוכל ונשנושים")}/>},
            // { path: "/חד פעמי", element: <ShopCategory banner={disposable_products_banner} category="חד פעמי" subCategories={getSubCategories("חד פעמי")}/>},
            // { path: "/מוצרי נקיון", element: <ShopCategory banner={clean_products_banner} category="מוצרי נקיון" subCategories={getSubCategories("מוצרי נקיון")}/>},
            // { path: "/מוצרים נלווים", element: <ShopCategory banner={related_products_banner} category="מוצרים נלווים" subCategories={getSubCategories("מוצרים נלווים")}/>},
            // { path: "/מבצעים", element: <ShopCategory banner={sale_banner} category="מבצעים" subCategories={getSubCategories("מבצעים")}/>},
            // { path: "/השכרת ציוד", element: <ShopCategory banner={equipment_rental_banner} category="השכרת ציוד" subCategories={getSubCategories("השכרת ציוד")}/>},
              
            
        ],
    },
]);

