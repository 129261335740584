import React, { useEffect, useState } from 'react'
import './breadcrum.css'
import { ItemType } from '../../Models/ItemModel'
import arrow_icon from '../Assets/breadcrum_arrow.png'

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';



export const Breadcrum = ({product}:{product?:ItemType}) => {

//     if (!product) {
//         return <div>No product available</div>;
//     }else{
//         return (
//             <div className='breadcrum'>
//                 דף הבית <img src={arrow_icon} alt="" /> חנות 
//                      <img src={arrow_icon} alt="" /> {product.category}
//                      <img src={arrow_icon} alt="" /> {product.name}
//             </div>
//           )
//     }
  
// }

const [productItem, setProductItem] = useState<ItemType>({
    key:0,
    id: 0,
    image: "",
    name: "",
    category:"",
    subCategory:"",
    old_price: 0,
    new_price: 0
})

const navigate = useNavigate();

useEffect(() => {
    if (product){
        console.log(product.category,product.subCategory)
        setProductItem(product);
    }
  }, [product])

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, path: string) {
  event.preventDefault();
  navigate(path);
}

// export default function CustomSeparator() {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={(e) => handleClick(e, '/')}>
      דף הבית
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href={`/${productItem.category}`}
      onClick={(e) => handleClick(e, `/${productItem.category}`)}
    >
      {productItem.category}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href={productItem.subCategory? `/${productItem.subCategory}`: `/${productItem.category}`}
      onClick={(e) => handleClick(e, productItem.subCategory ? `/${productItem.subCategory}` : `/${productItem.category}`)}
    >
      {productItem.subCategory? productItem.subCategory: "כל המוצרים"}
    </Link>,
    <Typography key="3" color="text.primary">
      {productItem.name}
    </Typography>,
  ];

  return (
    <Stack spacing={2} className='breadcrum'>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
