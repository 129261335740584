import './AddProduct.css'
import upload_area from '../Assets/upload_area.svg'
import { NavbarAdmin } from '../NavbarAdmin/NavbarAdmin';
import { Sidebar } from '../Sidebar/Sidebar';
import { categoryType } from '../../Models/CategoryModel';
import all_categories from "../Assets/all_categories"
import { useEffect, useState } from 'react';
import { addProduct } from '../../Services/productServices';
import { getSubCategories } from '../../utils/utils';
import { Category, CheckBox} from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import { ItemType } from '../../Models/ItemModel';
import { appConfig } from '../../utils/appConfig';
import { createFileFromLocalImage } from '../../utils/fileCreateUtils';

interface AddProductProps {
  product: ItemType;
  onClose: () => void;
  onSave: (product: ItemType, image: string|File) => void;
  action: string; // 
}


export const AddProduct = ({ product, onClose, onSave, action}: AddProductProps) => {

  const [image, setImage] = useState<string | File> ("");
  const [productDetails,setProductDetails] = useState(product);
//     action === "edit"? product : {
//     id: 0,
//     name:"",
//     image:"",
//     category:"",
//     subCategory:"",
//     new_price:0,
//     old_price:0,
//     information:"", 
//     special: false,
// });

  useEffect(() => {
      setImage(  (product.image ? product.image.replace('/src', '')  : upload_area));
  }, [product]);

const imageHandler = async(e: React.ChangeEvent<HTMLInputElement>) => {
  const files = e.target.files;
  if (files && files.length > 0) {
    setImage(files[0]);
  } else{
    const lastModifiedDate = new Date();
    const lastModified = lastModifiedDate.getTime();
    const file = await createFileFromLocalImage(String(image), String(product.image), lastModified);
    setImage(file);
  }
};

  const changeHandler = (e:any) => {
    console.log(e);

    if (e.target.name === "special"){
      setProductDetails({...productDetails,[e.target.name]:e.target.checked});
      console.log(productDetails.special);
    }else{
      setProductDetails({...productDetails,[e.target.name]:e.target.value});
      if (e.target.name === "category"){
        setProductDetails(prevDetails => ({
          ...prevDetails,
          subCategory: "" // Reset subCategory when category changes
        }));
      }
    }
  
  }

  const handleAddProduct = async () =>{
    console.log(productDetails);
    if ( action === "add"){
      await addProduct(productDetails, image);
    }else{
      // if (image === product.image.replace('/src', '')){
      if (image === product.image){
        console.log("hi");
        const lastModifiedDate = new Date();
        const lastModified = lastModifiedDate.getTime();
        const file = await createFileFromLocalImage(String(image), String(product.image), lastModified);
        setImage(file); 
      }
      console.log(image);
      onSave(productDetails, image);
      onClose();
    }
   
  }

  return (
    <>
      <div className="addproduct">
        <div className="addproduct-itemfield">
          <p>תיאור המוצר</p>
          <input type="text" name="name" value={productDetails.name} onChange={(e)=>{changeHandler(e)}}/>
        </div>
        <div className="addproduct-price">
          <div className="addproduct-itemfield">
            <p>מחיר</p>
            <input type="text" name="old_price" value={productDetails.old_price} onChange={(e)=>{changeHandler(e)}}/>
          </div>
          <div className="addproduct-itemfield">
            <p>מחיר אחרי הנחה</p>
            <input type="text" name="new_price" value={productDetails.new_price} onChange={(e)=>{changeHandler(e)}}/>
          </div>
        </div>
        <div className="category-subcategory-information">
          <div className="addproduct-itemfield category-item">
            <p>קטגוריה</p>
            <select name="category" className="add-product-selector" defaultValue= {action === "add" ? "": productDetails.category} onChange={(e)=>{changeHandler(e)}}>
                <option value="" disabled>
                  בחר קטגוריה
                </option>
              {all_categories.map((category: categoryType, index: number) => (
                <option key={index} value={category.category}>
                  {category.category}
                </option>
            ))}
            </select> 
            <p>תת קטגוריה</p>
            <select name="subCategory" className="add-product-selector" defaultValue= {action === "add" ? "": productDetails.subCategory} onChange={(e)=>{changeHandler(e)}}>
                <option value="" disabled={!productDetails.category}>
                  בחר תת קטגוריה
                </option>
              {getSubCategories(productDetails.category || "").map((subCategory: string, index: number) => (
                <option key={index} value={subCategory}>
                  {subCategory}
                </option>
            ))}
            </select> 
          </div>
          <div className="addproduct-itemfield category-item">
            <p>מידע על המוצר</p>
            <textarea 
            value={productDetails.information} 
            name = "information" 
            className='information'
            onChange={(e)=>{changeHandler(e)}}
            required
            />
            <div className='special-radio'>
            <Checkbox
              name = "special"
              checked={productDetails.special}
              onChange={(e)=>{changeHandler(e)}}
              inputProps={{ 'aria-label': 'controlled' }}
              color="primary"
            />
              <p>מוצר מיוחד</p>
            </div>
          </div>
        </div>
        <div className="addproduct-itemfield image-item">
          <p>הוסף תמונה</p>
          <label htmlFor="file-input">
            <img className="addproduct-thumbnail-img" src={image && image instanceof File ? URL.createObjectURL(image) : image } alt="" />
          </label>
          <input onChange={(e)=>{imageHandler(e)}} type="file" name="image" id="file-input" hidden />
        </div>
        <div className="actions-buttons">
          <button onClick={()=>{handleAddProduct()}} className="addproduct-btn">{action === "add"? "הוסף מוצר": "עדכן מוצר"}</button>
          {action === "edit" && (<button onClick={onClose} className="cancel-btn">
              בטל
          </button>)}
        </div>

      </div>
    </>
  );
};
