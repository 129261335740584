import React, { useContext, useEffect, useState } from 'react';
// import Button from '@mui/material/Button';
// import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

import './ProductQuantity.css'
import { ShopContext } from '../../Context/ShopContext';
import { CartItems } from '../CartItems/CartItems';

interface ProductQuantityProps{
    productId: number
}
export const ProductQuantity = ({productId }: ProductQuantityProps) => {

    const shopContext = useContext(ShopContext);
    const cartItems = shopContext[1];
    const addToCart = shopContext[2];
    const removeFromCart = shopContext[3];

    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        const fetchQuantity = ()=>{
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
              const parsedUser = JSON.parse(storedUser);
              setQuantity(parsedUser.cartData[productId]);
            } else{
              const storedCart = localStorage.getItem('cart');
              if (storedCart) {
                const parsedStoredCart = JSON.parse(storedCart);
                setQuantity(parsedStoredCart[productId]);
              }
          } 
        }
        fetchQuantity();
      }, [])

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
    addToCart(productId) ;
    // localStorage.setItem('cart', JSON.stringify(cartItems));
  };

  const handleDecrement = () => {
    setQuantity(prevQuantity => Math.max(prevQuantity - 1, 0)); // Ensure quantity doesn't go below 1
    removeFromCart(productId);
  };

  return (
    <>
        { quantity === 0 &&
        ( 
            <button className="addcart-button" onClick={handleIncrement}>הוסף לסל</button>
        )}
        { quantity > 0 &&
        ( <div className='quantity'>
            <p className="increment-button"onClick={handleIncrement}>+</p>
            <p className='quantity-value'>{quantity}</p>
            <p className="decrement-button" onClick={handleDecrement}>-</p>
        </div>
        )}
    </>
    
  );
};

