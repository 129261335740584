import React, { useContext, useRef, useState } from 'react'
import './navbar.css'
import logo from '../Assets/logo_new18.png'
import { BsCart } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import { GoSearch } from "react-icons/go";
import { RxDropdownMenu } from "react-icons/rx";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
// import { UilUser } from '@iconscout/react-unicons'

import disposable_products_img from '../Assets/disposable_products.png'
import clean_navbar_img from '../Assets/clean_navbar.png'
import home_page_img from '../Assets/home page.png'
import snacks_food_navbar_image from '../Assets/snacks_food_navbar.png'
import ice_img from '../Assets/ice2.png'
import alcohol_img from '../Assets/alcohol_navbar.png'
import related_products_navbar_img from '../Assets/related_products.png'
import equipment_rental_navbar_img from '../Assets/equipment_rental_navbar.png'
import soft_drinks_navbar_img from '../Assets/soft_drinks_navbar.png'
import sale_img from '../Assets/sale_img_navbar3.png'
import { ProductsSearch } from '../../Pages/ProductsSearch';
import { searchProducts } from '../../Services/productServices';
import new_logo from '../Assets/logo_new.png'
import { Avatar, Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; 


export const Navbar = () => {

    const shopContext = useContext(ShopContext);
    const getTotalCartItems = shopContext[5];
    const user = shopContext[6];
    const logout = shopContext[8];


    
    const [menu, setmenu] = useState("");
    const [query, setQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    // const menuRef = useRef<HTMLUListElement>(null);
    

    const navigate = useNavigate();
    const handleNavigation = (path: string) => {
        navigate(`/${path}`);
      };
    
    
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    const changeOnClick = (path:string) =>{
        console.log(path);
        setmenu(path);
        handleNavigation(path);
        // toggleMenu();
    }

    const handleLoginLogout = () => {
       
        if ((user.role === "admin") || (user.role === "user")) {
          logout();
          handleNavigation("")
        } else {
          setmenu("other");
          setQuery("");
          handleNavigation("התחברות")
        }
      };

    const handleSearch = async () =>{

        if(query){
            setmenu("other");
            setQuery("");
            handleNavigation(`searchResults?query=${query}`);
        }
        // const Results = await searchProducts(query);
        // setSearchResults(Results);
        // console.log(searchResults);

    }

    const stringToColor = (string: string) =>{
        let hash = 0;
        let i;
    
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
    
        let color = '#';
    
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
    
        return color;
    }

    const stringAvatar= (name: string) => {
    const upperCaseName = name.toUpperCase();
        return {
            sx: {
            bgcolor: stringToColor(name),
            },
            children: `${upperCaseName.split(' ')[0][0]}${upperCaseName.split(' ')[1][0]}`,
    };
    }

    // const dropdownToggle = (event) => {
    //     menuRef.current?.classList.toggle('nav-menu-visible');
    //     event.target.classList.toggle('open');
    // }
    
    return (
        <>
        <div className='freeDelivery'>
            <p>
                ₪   משלוח חינם<span className='underline'> עד מקום האירוע</span> - בהזמנה מעל 1500 
            </p>
        </div>
        <div className="navbar">
            <div className="searchBar">
                <div className="searchInputIcon">
                    <input className='searchInput'
                    type="text" 
                    value={query} 
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder='מה חסר לכם? חפשו כאן!'/>
                    <GoSearch 
                    onClick={handleSearch}
                    size={30} 
                    className='goSearch'/>
                </div>
            </div>
            {/* <div className="account-icon">
                <i className    ="uil uil-user"></i>
            </div> */}
            <div className="nav-logo" onClick= {() => {changeOnClick("")}}>
                <img src={logo} alt="" />
                {/* <h1> כל מה שברים ניידים צריכים</h1> */}
                {/* <p onClick= {() => {changeOnClick("")}}>HaNAYADEM</p> */}
            </div>
            <div className="nav-login-cart">
                {user.role !== "guest" && (
                <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent= "center"
                // gap={1}
                sx={{ mr: 4 }}
                >
                {/* <Avatar {...stringAvatar(`${user.firstName} ${user.lastName}`)} /> */}
                <AccountCircleIcon color='success' sx={{
                        fontSize: [24, 32, 40, 52] // xs, sm, md, lg, xl
                }}  />
                <Typography color='white' variant="body1">{user.firstName} {user.lastName}</Typography>
                </Box>
                )}
                <button onClick= {handleLoginLogout}>{user.role === "guest" ? "התחברות" : "התנתק"}</button>
                {/* <img src={cart_icon} alt="" /> */}
                <BsCart className="nav-cart" color='white' size={32} onClick= {() => {changeOnClick("cart")}} />
                <div className="nav-cart-count">{getTotalCartItems()}</div>
            </div>
            <div className='dropDown-and-menu'>
                <MenuIcon className="nav-dropdown" onClick={toggleMenu}/>
                {/* <RxDropdownMenu className="nav-dropdown" onClick={toggleMenu}/> */}
                {/* <div className={isOpen ? "nav-menu-open" : 'nav-menu-not-open'}> */}
                <div className={`nav-menu-not-open ${isOpen ? 'open' : ''}`}>
                    <div className="headline-closeIcon" >
                        <h3 className="headline">קטגוריות</h3>
                        <button onClick={toggleMenu} className="close-button">X</button>
                    </div>
                    {/* <ul className={isOpen ? "nav-menu-open" : 'nav-menu-not-open'}> */}
                    <ul >
                        <li onClick= {() => {changeOnClick("השכרת ציוד")}}>השכרת ציוד </li>
                        <li onClick= {() => {changeOnClick("מוצרים נלווים")}}>מוצרים נלווים </li>
                        <li onClick= {() => {changeOnClick("מוצרי נקיון")}}>מוצרי נקיון </li>
                        <li onClick= {() => {changeOnClick("חד פעמי")}}>חד פעמי </li>
                        <li onClick= {() => {changeOnClick("אוכל ונשנושים")}}>אוכל ונשנושים </li>
                        <li onClick= {() => {changeOnClick("שתייה קלה")}}>שתייה קלה </li>
                        <li onClick= {() => {changeOnClick("אלכוהול")}}>אלכוהול </li>
                        {/* <li onClick= {() => {changeOnClick("קרח")}}>קרח</li> */}
                        <li onClick= {() => {changeOnClick("מבצעים")}}>מבצעים</li>
                    </ul>
                </div>
            </div>
        </div>
        <div>
            <ul className="nav-menu">
                <li onClick= {() => {changeOnClick("השכרת ציוד")}}>
                    <div className='category-name-and-icon'>
                        <img src={equipment_rental_navbar_img} alt="" />
                        <div> השכרת ציוד {menu==="השכרת ציוד"?<hr/>:<></>}</div>
                    </div> 
                </li>
                <li onClick= {() => {changeOnClick("מוצרים נלווים")}}>
                    <div className='category-name-and-icon'>
                        <img src={related_products_navbar_img} alt="" />
                        <div> מוצרים נלווים {menu==="מוצרים נלווים"?<hr/>:<></>}</div>
                    </div> 
                </li>
                <li onClick= {() => {changeOnClick("מוצרי נקיון")}}>
                    <div className='category-name-and-icon'>
                        <img src={clean_navbar_img} alt="" />
                        <div> מוצרי נקיון {menu==="מוצרי נקיון"?<hr/>:<></>}</div>
                    </div> 
                </li>
                <li onClick= {() => {changeOnClick("חד פעמי")}}>
                    <div className='category-name-and-icon'>
                        <img src={disposable_products_img} alt="" />
                        <div> חד פעמי {menu==="חד פעמי"?<hr/>:<></>}</div>
                    </div> 
                </li>
                <li onClick= {() => {changeOnClick("אוכל ונשנושים")}}>
                    <div className='category-name-and-icon'>
                        <img src={snacks_food_navbar_image} alt="" /> 
                        <div> אוכל ונשנושים {menu==="אוכל ונשנושים"?<hr/>:<></>}</div>
                    </div> 
                </li>
                <li onClick= {() => {changeOnClick("שתייה קלה")}}>
                    <div className='category-name-and-icon'>
                        <img src={soft_drinks_navbar_img} alt="" />
                        <div> שתייה קלה {menu==="שתייה קלה"?<hr/>:<></>}</div>
                    </div> 
                </li>
                <li onClick= {() => {changeOnClick("אלכוהול")}}>
                    <div className='category-name-and-icon'>
                        <img src={alcohol_img} alt="" />
                        <div> אלכוהול {menu==="אלכוהול"?<hr/>:<></>}</div>
                    </div> 
                </li>
                {/* <li onClick= {() => {changeOnClick("קרח")}}>
                    <div className='category-name-and-icon'>
                        <img src={ice_img} alt="" />
                        <div> קרח {menu==="קרח"?<hr/>:<></>}</div>
                    </div> 
                </li> */}
                <li onClick= {() => {changeOnClick("מבצעים")}}>
                    <div>
                        <img className="sale-img" src={sale_img} alt="" />
                        <div>{menu==="מבצעים"?<hr/>:<></>}</div>
                    </div> 
                </li>
                <li onClick= {() => {changeOnClick("")}}>
                    <div className='category-name-and-icon'>
                        <img src={home_page_img} alt="" />
                        <div> דף הבית {menu===""?<hr/>:<></>}</div>
                    </div> 
                </li>
            </ul>
        </div>
        </>
    )
}

export const MobileNavbar = () => {
    return <section className="mobileNavbarContainer"></section>;
  };
