import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { ItemType } from '../Models/ItemModel';
import { CartType } from '../Models/CartModel';
import { ShopContextType } from '../Models/ShopContextModel';
import { UserType } from '../Models/UserModel';
import { getAllProducts } from '../Services/productServices';
import { addProductToCart, removeAllProductsFromCart, removeProductFromCart } from '../Services/cartServices';
import { getUserInformation } from '../Services/authServices';
import { useNavigate } from 'react-router-dom';


const initialUser: UserType= {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  role: 'guest',
  cartData: {},
  date: '',
  vip: false
} 

interface UserContextType {
  user: UserType;
  login: (userData: UserType) => void;
  logout: () => void;
}

// Define the initial value for the context
 const initialContextValue = [[], {}, () => {}, () => {}, () => 0, () => 0,
  initialUser,
 () => {},
 () => {},
 () => {},
 ] as unknown as ShopContextType; 

 // Create the context with the initial value
export const ShopContext = createContext(initialContextValue);


const getDeafualtCart = () =>{
  let cart: CartType = {} as CartType;
  for (let i = 0; i< 300+1; i++){
    cart[i] = 0;
  }
  return cart;
}

  const ShopContextProvider: React.FC<{children:ReactNode}> = ({children}) => {
   
    const [user, setUser] = useState<UserType>(() => {
      const storedUser = localStorage.getItem('user');
      return storedUser ? JSON.parse(storedUser) : initialUser;
    });

    const updateUserCartItemsInDB = ( cart: CartType) =>{
      if (localStorage.getItem("token") && localStorage.getItem('user')) {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          const parsedUser = JSON.parse(storedUser);
          parsedUser.cartData = cart;
          localStorage.setItem('user', JSON.stringify(parsedUser));
          setUser(parsedUser); 
          //***there is better solution********/
          console.log("hhhhhhhhhhhhhhhhhhhhh");
          // removeAllProductsFromCart(parsedUser.id);
          console.log("kkkkkkkkkkkkkkkkkkkkkk");
          Object.entries(cart).forEach(([productId, quantity]) => {
            if (quantity > 0) {
              for( let i = 0; i< quantity; i++){
                addProductToCart(parseInt(productId, 10), parsedUser.id);
                console.log(parseInt(productId, 10));
              }
            }
          });
        }
      }
    }
    
    const areAllValuesZero =(cart: CartType): boolean => {
      return Object.values(cart).every(value => value === 0);
    }

    const login = (userData:UserType) => {

      let allValuesZero = null;
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      const storedCart = localStorage.getItem('cart');
      if (storedCart) {
        const parsedstoredCart = JSON.parse(storedCart);
        allValuesZero = areAllValuesZero(parsedstoredCart);
        if (!allValuesZero){
          setCartItems(parsedstoredCart);
          updateUserCartItemsInDB(parsedstoredCart);
          localStorage.removeItem('cart');
        }else{
          setCartItems(userData.cartData);
          localStorage.removeItem('cart');
        }

      }
      console.log(storedCart);

      // if (storedCart) {
      //   const parsedstoredCart = JSON.parse(storedCart);
      //   setCartItems(parsedstoredCart);
      //   updateUserCartItemsInDB(parsedstoredCart);
      //   localStorage.removeItem('cart');
      // } else{
      //   setCartItems(userData.cartData);
      // }

    }
  
    const logout = () => {
      setUser(initialUser);
      localStorage.removeItem('user');
      localStorage.removeItem('cart');
      setCartItems(getDeafualtCart);
      
      // Remove the token from localStorage
      const token = localStorage.getItem('token');
      if (token){
        localStorage.removeItem('token');
      };
    }
 
    const [cartItems,setCartItems] = useState(getDeafualtCart());
    const [allProducts,setAllProducts] = useState<ItemType[]>([]);

   
    // let allProducts = [] as ItemType[];
    // allProducts = all_product;
    const fetchAllProducts = async() =>{
      const allFetchedProducts = await getAllProducts();
      setAllProducts(allFetchedProducts);
      console.log(allFetchedProducts)
    }
   
    const fetchUser = async() =>{
      const userData = await getUserInformation();
      console.log("produc" + userData.cartData[30]);
      localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
      
    }
    useEffect(() =>{
      const fetchData = async () => {
        await fetchAllProducts();
        // await fetchUser();
      };
      fetchData();
    },[])

    // useEffect(() => {
    //   // if (user && user.cartData) {
    //   //   setCartItems(user.cartData);
    //   // }
    //   if (localStorage.getItem("token") && localStorage.getItem('user')) {
    //     const storedUser = localStorage.getItem('user');
    //     if (storedUser) {
    //       const parsedUser = JSON.parse(storedUser);
    //       setCartItems(parsedUser.cartData);
    //     }
    //   } else{
    //     const storedCart = localStorage.getItem('cart');
    //     if (storedCart) {
    //       setCartItems(JSON.parse(storedCart));
    //     }
    //   };
    // }, []);

    
    useEffect(() => {
      //localStorage.setItem('cart', JSON.stringify(cartItems));
      
      const storedCart = localStorage.getItem('cart');
      console.log(storedCart);
      const storedUser = localStorage.getItem('user');
      const storedToken = localStorage.getItem("token");
      
      if (storedCart && storedUser) {
        const parsedstoredCart = JSON.parse(storedCart);
        setCartItems(parsedstoredCart);
        updateUserCartItemsInDB(parsedstoredCart);
      }else{
        if (storedCart && !storedUser) {
          const parsedstoredCart = JSON.parse(storedCart);
          setCartItems(parsedstoredCart);
        }else{
          if (!storedCart && storedUser) {
            const parsedUser = JSON.parse(storedUser);
          setCartItems(parsedUser.cartData);
          }  
        }
      }
      
    }, []);
   
    // const addToCart = (itemId:number) => {
    //   setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
      
    //   if(localStorage.getItem("token") && localStorage.getItem('user')){ 
    //     const storedUser = localStorage.getItem('user');
    //     if (storedUser) {
    //       const parsedUser = JSON.parse(storedUser);
    //       parsedUser.cartData = cartItems;
    //       // user.cartData = cartItems;
    //       localStorage.setItem('user', JSON.stringify(parsedUser));
    //       addProductToCart(itemId, user.id);
    //     }
       
    //   }
    // }
    
    const addToCart = (itemId: number) => {
      setCartItems((prev) => {
        const updatedCartItems = { ...prev, [itemId]: (prev[itemId] || 0) + 1 };
    
        // Update localStorage
        if (localStorage.getItem("token") && localStorage.getItem('user')) {
          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            parsedUser.cartData = updatedCartItems;
            localStorage.setItem('user', JSON.stringify(parsedUser));
            setUser(parsedUser); 
            addProductToCart(itemId, parsedUser.id);
          }
        }else{
          localStorage.setItem('cart', JSON.stringify(updatedCartItems));
        }
    
        return updatedCartItems;
      });
    };

    const removeFromCart = (itemId: number) => {
      setCartItems((prev) => {
        const updatedCartItems = { ...prev, [itemId]: (prev[itemId] || 0) - 1 };
    
        // Update localStorage
        if (localStorage.getItem("token") && localStorage.getItem('user')) {
          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            parsedUser.cartData = updatedCartItems;
            localStorage.setItem('user', JSON.stringify(parsedUser));
            setUser(parsedUser);
            removeProductFromCart(itemId, parsedUser.id);
          }
        }else{
          localStorage.setItem('cart', JSON.stringify(updatedCartItems));
        }
    
        return updatedCartItems;
      });
    };


    const removeAllFromCart = () => {
      setCartItems((prev) => {
        const updatedCartItems = { ...prev};
        Object.keys(updatedCartItems).forEach((key) => {
          updatedCartItems[parseInt(key, 10)] = 0;
        });
    
        // Update localStorage
        if (localStorage.getItem("token") && localStorage.getItem('user')) {
          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            parsedUser.cartData = updatedCartItems;
            localStorage.setItem('user', JSON.stringify(parsedUser));
            setUser(parsedUser);
            removeAllProductsFromCart(parsedUser.id);
          }
        }
    
        return updatedCartItems;
      });
    };

    // const removeFromCart = (itemId:number) => {
    //   setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    //   if(localStorage.getItem("token")){
    //     removeProductFromCart(itemId, user.id);
    //   }
    // }
  
    const getTotalCartAmount = () => {
      let totalAmount = 0;
      for (const item in cartItems) {
        if (cartItems[item] > 0) {
          let itemInfo = allProducts.find((product) => product.id === Number(item));
          if (itemInfo !=null){
            totalAmount += cartItems[item] * itemInfo.new_price;
          }
        }
      }
      return totalAmount;
    };

    const getTotalCartItems = () => {
      let totalItem = 0;
      for (const item in cartItems) {
        if (cartItems[item] > 0) {
          totalItem += cartItems[item];;
        }
      }
      return totalItem;
    };

    const contextValue: ShopContextType = [allProducts, cartItems, addToCart, removeFromCart, getTotalCartAmount, getTotalCartItems, user, login, logout, removeAllFromCart ] ;
    
    return (
      <ShopContext.Provider value ={contextValue}>
        {children}
      </ShopContext.Provider>
    );
}
  
export default ShopContextProvider;


// // Define the type for your context data
// interface MyContextData {
//   username: string;
//   isLoggedIn: boolean;
// }

// // Create a context object with an initial value
// const MyContext = createContext<MyContextData>({
//   username: '',
//   isLoggedIn: false,
// });

// // Example of a component consuming the context
// const MyComponent: React.FC = () => {
//   // Access the context using the useContext hook
//   const contextData = useContext(MyContext);

//   return (
//     <div>
//       <p>Username: {contextData.username}</p>
//       <p>Is logged in: {contextData.isLoggedIn ? 'Yes' : 'No'}</p>
//     </div>
//   );
// };

// // Example of a component providing the context
// const App: React.FC = () => {
//       // Define the data to be passed through the context
//       const contextValue: MyContextData = {
//         username: 'example_user',
//         isLoggedIn: true,
//       };

//       return (
//         <MyContext.Provider value={contextValue}>
//           <MyComponent />
//         </MyContext.Provider>
//       );
// };

// export default App;
// In this example, we define a context using createContext and specify the type of data that will be passed through the context. We then provide the context value using the Provider component and access it in consuming components using the useContext hook. This allows us to maintain type safety throughout our application when working with context data in React with TypeScript.
