import React, { useContext } from 'react';
import { Navigate} from 'react-router-dom';
import {ShopContext} from '../../Context/ShopContext';


interface ProtectedRouteProps {
  role?: string;
  children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ role, children}) => {
  
  // get context values
  const shopContext = useContext(ShopContext);
  const user = shopContext[6];
    

  if (user.role === "guest") {
    return <Navigate to="/התחברות" />;

  }else{
    if (role !== user.role) {
        if ( user.role === "user"){
            return <Navigate to="/" />;
        }
        if ( user.role === "admin"){
            return <Navigate to="/admin" />;
        }
        else{
            return <Navigate to="/התחברות" />;
        }
    }else{
        return <>{children}</>;
    }
  }

};

