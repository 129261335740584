import React, { useContext } from 'react'
import './relatedProducts.css'
import { Item } from '../Item/Item'
import { ItemType } from '../../Models/ItemModel'
import {ShopContext} from '../../Context/ShopContext'


export const RelatedProducts = ({product}:{product?:ItemType})=> {

  const shopContext = useContext(ShopContext);
  const allProducts = shopContext[0];

  const getRandomItems = (items: ItemType[], count: number) => {
    // Shuffle the array
    const shuffled = items.sort(() => 0.5 - Math.random());
    // Get sub-array of first n elements after shuffled
    return shuffled.slice(0, count);
  };
  
  const filteredItems = allProducts.filter(item => 
    product?.category === item.category || product?.subCategory === item.subCategory
  );
  
  const randomItems = getRandomItems(filteredItems, 4);

  return (
    <div className='relatedproducts'>
        <h1 > !בטוח שתאהבו גם</h1>
        <hr/>
        <div className="relatedproducts-item">
            {randomItems.map((item,i) => {
              if (product?.category === item.category || product?.subCategory === item.subCategory ){
                return <Item key={i} 
                id={item.id} 
                name={item.name} 
                image={item.image}
                new_price={item.new_price}
                old_price={item.old_price}/>
              }
            })}
        </div>
    </div>
  )
}
