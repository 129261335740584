import { NavbarAdmin } from '../components/NavbarAdmin/NavbarAdmin';
import { AddProduct } from '../components/AddProduct/AddProduct';
import { ListProduct } from '../components/ListProduct/ListProduct';
import { useState } from 'react';
import add_product_icon from '../components/Assets/Product_Cart.svg'
import list_product_icon from '../components/Assets/Product_list_icon.svg'
import "./CSS/Admin.css"

export const Admin = () => {

  const [pageContent, setPageContent] = useState<string|null>(null);

  const showAddProduct = () =>{
    setPageContent("addProduct");
  }

  const showProductsList = () =>{
    setPageContent("productsList");
  }

  const product = {
    id: 0,
    name:"",
    image:"",
    category:"",
    subCategory:"",
    new_price:0,
    old_price:0,
    information:"", 
    special: false,
};

  return (
    <div className='admin'>
      <NavbarAdmin />
      <div className='mainContent'>
        <div className='sidebar'>
          <div onClick = {() => showAddProduct()} className="sidebar-item">
            <img src={add_product_icon} alt="" />
            <p>הוספת מוצר חדש</p>
          </div>
          <div onClick = {() => showProductsList()} className="sidebar-item">
            <img src={list_product_icon} alt="" />
            <p>רשימת מוצרים</p>
          </div>
        </div>
        <div className="content">
          { pageContent === "addProduct" && (<div> <AddProduct product ={product} onClose={() =>{}} onSave={() => {}} action="add"/></div>)}
          { pageContent === "productsList" && (<div> <ListProduct/></div>)}
        </div>
      </div>
    </div>
      
  ) 
}
