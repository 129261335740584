import axios from "axios";
import { ItemType } from "../Models/ItemModel";
import { appConfig } from "../utils/appConfig";
import { useContext } from "react";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import pdfMake from "pdfmake/build/pdfmake";



export const addProductToCart = async (itemId: number, userId: number)=> {
    const addToCartFullUrl = appConfig.baseUrl + appConfig.post.addToCart + userId;
    console.log(addToCartFullUrl);
    await axios
    .post(addToCartFullUrl,{"itemId":itemId}) 
    .then((res) => res.data)
    .catch(err => {
        console.log(err);
        console.error('Error Adding Product To Cart:', err.response ? err.response.data : err.message);
        throw err;
    })
}

export const removeProductFromCart = async (itemId: number, userId: number)=>{
    const removeFromCartFullUrl = appConfig.baseUrl + appConfig.post.removeFromCart + userId;

    await axios
    .post(removeFromCartFullUrl,{"itemId":itemId}) 
    .then((res) => res.data)
    .catch(err => {
        console.log(err);
        console.error('Error removind Product From Cart:', err.response ? err.response.data : err.message);
        throw err;
    })
}

export const removeAllProductsFromCart = async (userId: number)=>{
  const removeAllProductsFromCartFullUrl = appConfig.baseUrl + appConfig.post.removeAllProductsFromCart + userId;

  await axios
  .post(removeAllProductsFromCartFullUrl) 
  .then((res) => res.data)
  .catch(err => {
      console.log(err);
      console.error('Error Removing All Products From Cart:', err.response ? err.response.data : err.message);
      throw err;
  })
}

// export const getAllProducts = async (): Promise<ItemType[]> => {
//     const fullUrl = appConfig.baseUrl + appConfig.get.allProducts;

//     const data = await axios
//     .get(fullUrl)
//     .then((res) => res.data as ItemType[])
//     .catch(e => console.log(e))

//     return data ? data as ItemType[]: []; 
// }

// export const addProduct = async (product: ItemType, image:string | File) => {
//     const uploadImageFullUrl = appConfig.baseUrl + appConfig.post.uploadImage;
//     const addProductFullUrl = appConfig.baseUrl + appConfig.post.addProduct;

//     const formData = new FormData();
//     formData.append('product', image);

//     const data = await axios
//     .post(uploadImageFullUrl,formData)
//     .then((res) => res.data)
//     .catch(err => {
//         console.log(err);
//         console.error('Error uploading file:', err.response ? err.response.data : err.message);
//         throw err;
//     })

//     if (data.success){
//         product.image = data.image_url;
//         console.log(product);

//          const productData = await axios
//         .post(addProductFullUrl,product) //Json.stringify(product)
//         .then((res) => res.data)
//         .then((productData) => {productData.message === "Created Successfully" ?alert("Product Added"):alert("Failed")})
//         .catch(err => {
//             console.log(err);
//             console.error('Error Adding Product:', err.response ? err.response.data : err.message);
//             throw err;
//         })
//         return productData;
//     }
// }


// export const removeProduct = async (id: number): Promise<void> => {
//     const fullUrl = appConfig.baseUrl + appConfig.post.removeProduct;

//     await axios
//     .post(fullUrl, ({"id":id}))
//     .then((res) => console.log("Product was removed status: " + res.status))
//     .catch(e => console.log(e))

// }

export const createAndSendPDFByMail = async(docDefinition: TDocumentDefinitions, email:string ) => {
    const fullUrl = appConfig.baseUrl + appConfig.post.createAndSendPDFByMail;

    // Create the PDF and convert to Blob
    pdfMake.createPdf(docDefinition).getBlob((blob) => {
    // Create form data to send the PDF as file
    const formData = new FormData();
    formData.append('file', blob, 'order-summary.pdf');
    formData.append('email', email);

    // Send the PDF to backend
    axios.post(fullUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      console.log('Email sent successfully');
    }).catch(error => {
      console.error('Error sending email:', error);
    });
  });
};

export const continueToPayment = async (
  action: string, 
  What: string,
  key: string, 
  PassP: string,
  Masof: string,
  Info: string,
  ClientName: string,
  Order: string,
  amount: string,
  utf8: string,
  utf8out: string,
  userId: string,
  clientLName: string,
  street: string,
  email: string,
) => {
  const continueToPaymentFullUrl = appConfig.baseUrl + appConfig.post.continueToPayment;
  const url = "city=netanya&zip=42361&phone=098610338&cell=050555555555&email=fadizedan@gmail.com&Tash=2&FixTash=False&ShowEngTashText=False&Coin=1&Postpone=False&J5=False&Sign=True&MoreData=True&sendemail=True&SendHesh=True&heshDesc=[0~Item 1~1~8][0~Item 2~2~1]&Pritim=True&PageLang=HEB&tmp=1";
  // const formData = new FormData();
 
  // formData.append('action', action);

  // console.log(formData);
  const response = await axios
    .post(continueToPaymentFullUrl,{"action":action, 
      "What":What, 
      "KEY":key, 
      "PassP":PassP,
      "Masof":Masof,
      "Info":Info,
      "ClientName":ClientName,
      "Order":Order,
      "Amount": amount,
      "UTF8": utf8,
      "UTF8out": utf8out,
      "UserId": userId,
      "ClientLName": clientLName,
      "street": street,
      "tmp": "1",
      "email": email,
      "sendemail": "True",
      "SendHesh" :"True",
    }) 
    .then((res) => res.data)
    .catch(err => {
        console.log(err);
        console.error('Error in Payment Process:', err.response ? err.response.data : err.message);
        throw err;
    })

  return response;

}
//   const url = 'https://icom.yaad.net/p/';
//   const body = { 
//     action: 'APISign',
//     What : 'SIGN',
//     Masof: '0010131918',
//     Info: 'Transaction description',
//     Amount: '100',
//     UTF8: 'True',
//     UTF8out: 'True',
//     J5: 'True',
//     PassP: 'yaad',
//     UserId: '111111111',
//     ClientName: 'Israel',
//     ClientLName: 'Israely',
//     street: 'New York',
//     city: 'New York',
//     zip: '1600',
//     phone: '+00',
//     cell: '+00',
//     email: 'abc@yaad.net',
//     Order: 'order information',
//     Tash: '2',
//     Sign: 'True',
//     MoreData: 'True',
//     sendemail: 'True',
//     Coin: '2',
//     CC: '4580121900146022',
//     Tmonth: '12',
//     Tyear: '21',
//     key: '7110eda4d09e062aa5e4a390b0a572ac0d2c0220'
//   };

//   try {
//     const response = await axios.post(url, body, {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     });

//     console.log('Status:', response.status);
//     console.log('Headers:', response.headers);
//     console.log('Body:', response.data);
//   } catch (error:any) {
//     console.error('Error:', error.response ? error.response.data : error.message);
//   }
// };


