import { AppConfig } from "../Models/AppConfig";


export const appConfig: AppConfig = {
    // baseUrl: "http://localhost:3001/api",
    // imagesFolder: "http://localhost:3001/Assets/",
   
    baseUrl: " https://backend-dot-hanayadeem.ew.r.appspot.com/api",
    imagesFolder: " https://backend-dot-hanayadeem.ew.r.appspot.com/Assets/",

    get: {
        oneVacation: "/vacations/",
        allProducts:"/allproducts",
        newProducts:"/newproducts",
        popularproducts:"/popularProducts",
        productSearch: "/search",
        specialProducts: "/specialProducts",
        upcomingVacations: "/upcomingvacations",
        activeVacations: "/activevacations",
        allFollowersForAllVacations: "/followers",
        oneUserFollowOneVacation: "/followers/",
        allVacationsFollowedByUser: "/followed/",
        allUsersFollowingParticularVacation: "/followers/",
        numberOfUsersFollowingParticularVacation: "/followersnumber/",
        allVacationsWithNumOfFollowers: "/followersnumber",
        followedVacationsByUserWithfollowers: "/followedwithfollowers/"
      },
  
      post: {
        addProduct: "/addproduct",
        uploadImage: "/upload",
        removeProduct: "/removeproduct",
        addToCart: "/addtocart/",
        removeFromCart: "/removefromcart/",
        removeAllProductsFromCart: "/removeAllFromCart/",
        suggestProduct: "/suggest-product",
        createAndSendPDFByMail: "/sendPdfByMail",
        continueToPayment: "/continueToPayment",
      },
  
      update: {
        updateProduct: "/updateProduct",
      },

      delete: {
        vacation: "/vacations/",
        follower: "/unfollow/",
      },

      auth: {
        // login: 'http://localhost:3001/api/auth/login',
        // signup: 'http://localhost:3001/api/auth/signup',
        // userInformation: 'http://localhost:3001/api/auth/user',
        // forgotPassword: 'http://localhost:3001/api/auth/forgot-password',
        // resetPassword: 'http://localhost:3001/api/auth/reset-password/'

        login: 'https://backend-dot-hanayadeem.ew.r.appspot.com/api/auth/login',
        signup: 'https://backend-dot-hanayadeem.ew.r.appspot.com/api/auth/signup',
        userInformation: 'https://backend-dot-hanayadeem.ew.r.appspot.com/api/auth/user',
        forgotPassword: 'https://backend-dot-hanayadeem.ew.r.appspot.com/api/auth/forgot-password',
        resetPassword: 'https://backend-dot-hanayadeem.ew.r.appspot.com/api/auth/reset-password/'
      },

     

}