import React, { ChangeEvent, useState } from 'react'
import './newsletter.css'
import { suggestProducts } from '../../Services/productServices';

export const Newsletter = () => {

  const [productName, setProductName] = useState('');
  const [message, setMessage] = useState<string>('');

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setProductName(e.target.value);
  };

  const handleOnClick = async (e: any) => {
    e.preventDefault();
    if (!productName) {
      setMessage('Product name is required');
      return;
    }

    try {
      const message = suggestProducts(productName, "Rami the king");
      setMessage(await message);
      setProductName('');
    } catch (error) {
      setMessage('Failed to submit product suggestion');
    }
  };
  
  return (
    <>
      <div className='newsletter'>
        <h1> !?חפשת משהו ולא מצאת</h1>
        <p>תרשום לנו ואנחנו נדאג להוסיף אותו </p>
        <div>
            <button onClick={handleOnClick}>שתף איתנו</button>
            <input 
            type="text" 
            placeholder='שם המוצר    '
            value={productName}
            onChange={changeHandler}/>
        </div>
        {message && <p className='message'>{message}</p>}
      </div>
    </>
   
  )
}
