import React, { useContext, useState } from 'react'
import'./cartItems.css'
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assets/cart_cross_icon.png'
import { ProductQuantity } from '../ProductQuantity/ProductQuantity';
import { ItemType } from '../../Models/ItemModel';
import { Link } from 'react-router-dom';
import { DeliveryDetails } from '../DeliveryDetails/DeliveryDetails';
import { ConfirmationPopUp } from './ConfirmationPopUp';
import { setDefaultResultOrder } from 'dns';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const CartItems = () => {

    const shopContext = useContext(ShopContext);
    const allProducts = shopContext[0];
    const cartItems = shopContext[1];
    const removeFromCart = shopContext[3];
    const getTotalCartAmount = shopContext[4];

    const [showDeletePopUp, setShowDeletePopUp] = useState<boolean>(false);
    const [deletedProduct, setDeletedProduct] = useState<ItemType|null>(null);

    const handleDeleteClick = (product: ItemType|null) => {
        setDeletedProduct(product);
        setShowDeletePopUp(true);
    };

    const handleDeleteCancel = () => {
        setShowDeletePopUp(false);
        setDeletedProduct(null);
    };

    const  handleDeleteConfirm = () => {
        if (deletedProduct !== null) {
          try {
            removeProductFromCart(deletedProduct);
          } catch (error) {
            console.error("Failed to delete product", error);
          } finally {
            setShowDeletePopUp(false);
            setDeletedProduct(null);
          }
        }
    };

    const removeProductFromCart = (item: ItemType) => {
        for (let index = 0; index < cartItems[item.id] ; index++) {
           removeFromCart(item.id)
        }
    }

  return (
    <div className='cartitems'>
        <div className="cartitems-format-main">
            <p>מוצר</p>
            <p>תיאור</p>
            <p>מחיר</p>
            <p>כמות</p>
            <p>סך הכל</p>
            <p>מחק</p>
        </div>
        <hr className="main-splitter"/>
        {allProducts.map((item) =>{
             if(cartItems[item.id] > 0){
                return <>
                <div className="cartitems-format cartitems-format-main">
                    {/* <img src={item.image.replace('/src', '')} alt="" className='carticon-product-icon'/> */}
                    <Link to={`/product/${item.id}`}> <img className='carticon-product-icon' src={item.image.replace('/src', '')} alt="" /> </Link>
                    <p className='cartitems-item-name'>{item.name}</p>
                    <p>{'\u20AA'}{item.new_price}</p>
                    {/* <button className='cartitems-quantity'>{cartItems[item.id]}</button> */}
                    <div className="cartitems-quantity">
                        <ProductQuantity productId={item.id}/>
                    </div>
                    <p>{'\u20AA'}{item.new_price*cartItems[item.id]}</p>
                    {/* <img className="cartitems-remove-icon" src={remove_icon}  alt="" onClick={() => {handleDeleteClick(item)}}/> */}
                    <Button onClick={()=>{handleDeleteClick(item)}} className="cartitems-remove-icon"><DeleteForeverIcon /></Button>
                    <ConfirmationPopUp
                        open={showDeletePopUp}
                        title="הסר מוצר"
                        content="?האם אתה בטוח שאתה רוצה להסיר את המוצר מהסל שלך"
                        onConfirm={handleDeleteConfirm}
                        onCancel={handleDeleteCancel}
                    />
                </div>
                <hr className="main-splitter"/>
                </>
            }
            return null;
        })}
        <div className="cartitems-down">
            <div className="cartitems-total">
                <h1>סיכום ההזמנה שלך</h1>
                <div>
                    <div className="cartitems-total-item">
                        <p>סך ביניים</p>
                        <p>{'\u20AA'}{getTotalCartAmount()}</p>
                    </div>
                    <hr className="main-splitter"/>
                    <div className="cartitems-total-item">
                        <p>משלוח</p>
                        <p>חינם</p>
                    </div>
                    <hr className="main-splitter"/>
                    <div className="cartitems-total-item">
                        <h3>סך כל ההזמנה</h3>
                        <h3>{'\u20AA'}{getTotalCartAmount()}</h3>
                    </div>
                </div>
                {/* <button>המשך לתשלום</button> */}
            </div>
            <hr className=' main-splitter splitter'/>
            <div className="cartitems-promocode">
                <DeliveryDetails/>
                {/* <p>אם יש לך קוד קופון, הכנס כאן</p>
                <div className="cartitems-promobox">
                    <input type="text" placeholder='קוד קופון'/>
                    <button>שלח</button>
                </div> */}
            </div>
        </div>
    </div>
  )
}
