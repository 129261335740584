import React from 'react'
import './offers.css'
import exclusive_image from '../Assets/bigsale5.png'
import { useNavigate } from 'react-router-dom';

export const Offers = () => {

  const navigate = useNavigate();

  const handleOnClick = () =>{
    navigate("מבצעים"); 
  }

  return (
    <div className='offers'>
        <div className="offers-left">
            {/* <img src={exclusive_image} alt="" /> */}
        </div>
        <div className="offers-right">
            <h1>מבצעים חמים</h1>
            {/* <h2>במיוחד בשבילכם</h2> */}
            <p>חבילות מיוחדות במחירים מעולים</p>
            <button onClick={handleOnClick}>כניסה</button>
        </div>
    </div>
  )
}
