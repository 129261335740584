import React, { useContext } from 'react'
import { ShopContext } from '../Context/ShopContext'
import { useParams } from 'react-router-dom';
import { Breadcrum } from '../components/Breadcrum/Breadcrum';
import { ProductDisplay } from '../components/ProductDisplay/ProductDispaly';
import { DescriptionBox } from '../components/DescriptionBox/DescriptionBox';
import { RelatedProducts } from '../components/RelatedProducts/RelatedProducts';



export const Product = () => {
  const shopContext = useContext(ShopContext);
  const allProducts = shopContext[0];
  const {id} = useParams();
  const product = allProducts.find((e) => e.id === Number(id));

  return (
    <div>
        <Breadcrum product={product} />
        <ProductDisplay product={product}/>
        {/* <DescriptionBox/> */}
        <RelatedProducts product={product}/>
    </div>
  )

}
