import React from 'react'
import { Hero } from '../components/Hero/Hero'
import { Popular } from '../components/Popular/Popular'
import { Offers } from '../components/Offers/Offers'
import { Specials } from '../components/Specials/Specials'
import { Newsletter } from '../components/Newletter/Newsletter'
import Banner from '../components/Banner/Banner'

export const Shop = () => {
  return (
    <div>
      <Hero/>
      {/* <Banner/> */}
      <Popular/>
      <Offers/>
      <Specials/>
      <Newsletter/>
    </div>
  )
}
