import axios from "axios"
import { LoginCredentials, SignupCredentials, UserType } from "../Models/UserModel"
import { appConfig } from "../utils/appConfig"


export const signinUser = async (data: LoginCredentials) => {
  console.log(data);
  const tokenData =  await axios.post(appConfig.auth.login, data);
  
  if (tokenData.data.success){
    const token = tokenData.data.token;
    localStorage.setItem('token',`${token}`);
    return token;
  }else{
    alert(tokenData.data.errors)
  }
  
}

export const signupUser = async (data: SignupCredentials) => {
  console.log(data);
  const correctData = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
  }
  const tokenData = await axios.post(appConfig.auth.signup, correctData);
  if (tokenData.data.success){
    const token = tokenData.data.token;
    localStorage.setItem('token',`${token}`);
    return token;
  }else{
    alert(tokenData.data.errors)
  }
  
}

export const getUserInformation = async ():Promise<UserType> => {
   
    try {
      const response = await axios.get(appConfig.auth.userInformation, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Cache-Control': 'no-cache', // Disable caching
            Pragma: 'no-cache', // HTTP 1.0 backward compatibility
            Expires: '0', // Disable caching 
        }
    });
    console.log("auth services" + response);
    
    return response.data as UserType;
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
};

export const sendPasswordResetLink = async (email :string): Promise<string> =>{
  
  console.log(email);
  try {
    const response = await axios.post(appConfig.auth.forgotPassword, 
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    alert(response.data.message);
    return response.statusText;

  } catch (error) {
    console.error('Error:', error);
    throw new Error('Error sending email.');
  }
};

export const resetPassword = async (password :string, token: string): Promise<string> =>{
  const resetPasswordFullUrl = appConfig.auth.resetPassword + token;
  console.log(password);
  try {
    const response = await axios.post(resetPasswordFullUrl , 
      { password },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    // alert(response.data.message);
    return response.statusText;
    
  } catch (error) {
    console.error('Error:', error);
    throw new Error('Error Reset Password.');
  }
};
